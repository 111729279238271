import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, navigate } from 'gatsby'
import { last, isEmpty } from 'lodash'
import { Container, useMediaQuery } from '@material-ui/core'
import SEO from 'components/Seo'
import Button from 'components/widgets/Button'
import Wavesf from 'components/Audio/audio-wave-surfer'
import ImageViewer from 'components/widgets/ImageViewer'
import FeaturedImage from './FeaturedImage'
import VideoPlayer from 'components/Video'
import { useAnalysis } from 'store/Analysis'
import * as storage from 'utils/localStorage'
import formatNumber, {
  getFeaturedImgs,
  getFeaturedAudio,
  getFeaturedVideo,
  getURL,
} from 'utils/common'
import { IS_HIDDEN_VIEWS, LS_EVENT_ID, TRACKING } from 'config/constants/common'
import { pageView } from 'utils/tracker'
import { fetchApiAnalysis } from 'utils/apis'
import './index.scss'

export default function TourExhibitDetail(props) {
  const { t } = useTranslation('common')
  const { panelsViews, getListPanelsViews } = useAnalysis()
  const [imgViewerSrc, setImgViewerSrc] = useState(null)
  const eventId = parseInt(storage.getItem(LS_EVENT_ID))
  const { data, pageContext } = props
  const { postgres } = data || {}
  const { ecmsEventPanelByPanelCode: exhibitDetail, allEcmsEventTours } = postgres || {}
  const {
    panelId = '',
    panelName = '',
    shortCode = '',
    panelDescription = '',
    resourceWebsite = '',
    ecmsEventTourPanelsByPanelId,
    ecmsEventFilesByPanelId,
  } = exhibitDetail || {}
  const { nodes: eventTours = [] } = allEcmsEventTours || {}
  const { nodes: eventToursExhibits = [] } = ecmsEventTourPanelsByPanelId || {}
  const { nodes: filesAttach = [] } = ecmsEventFilesByPanelId || {}
  const { exhibitCode, tourCode } = pageContext || {}
  const resourceWebURL = getURL(resourceWebsite)
  const screenWidth768 = useMediaQuery('(max-width: 768px)')
  const screenWidth480 = useMediaQuery('(max-width: 480px)')

  // get featured images
  const { featuredImgs, featuredAudio, featuredVideo } = useMemo(() => {
    const imgs = getFeaturedImgs(filesAttach, true)
    const featuredImgs = !isEmpty(imgs)
      ? imgs.map((img) => (typeof img === 'object' ? img.url : img))
      : [null] // use for show button prev, next

    const featuredAudio = getFeaturedAudio(filesAttach)
    const featuredVideo = getFeaturedVideo(filesAttach)

    return { featuredImgs, featuredAudio, featuredVideo }
  }, [filesAttach])

  // get siblings exhibit code
  const { prevExhibitCode, nextExhibitCode } = useMemo(() => {
    let prevExhibitCode = null
    let nextExhibitCode = null
    eventTours.forEach((eventTour) => {
      const { eventId: tourEventId, tourCode: itemTourCode, ecmsEventTourPanelsByTourId } =
        eventTour || {}
      if (tourEventId !== eventId || itemTourCode !== tourCode) {
        return
      }

      const { nodes: listExhibitionsBefore = [] } = ecmsEventTourPanelsByTourId || {}
      const listExhibitions = listExhibitionsBefore.filter((exhibitionItem) => {
        const { ecmsEventPanelByPanelId } = exhibitionItem || {}
        const { panelCode } = ecmsEventPanelByPanelId || {}

        return panelCode !== exhibitCode
      })
      // first exhibit
      const [firstExhibit] = listExhibitions
      const { ecmsEventPanelByPanelId: firstExhibitData = {} } = firstExhibit || {}
      prevExhibitCode = firstExhibitData.panelCode || ''

      // last exhibit
      const lastExhibit = last(listExhibitions) || {}
      const { ecmsEventPanelByPanelId: lastExhibitData = {} } = lastExhibit || {}
      nextExhibitCode = lastExhibitData.panelCode || ''

      // find current exhibit index
      const currentExhibitIndex = listExhibitionsBefore.findIndex((exhibition) => {
        const { ecmsEventPanelByPanelId: exhibitionData } = exhibition || {}
        const { panelCode: exhibitionDetailCode } = exhibitionData || {}

        return exhibitionDetailCode === exhibitCode
      })

      // if exists
      if (currentExhibitIndex !== -1) {
        const prevIndex = currentExhibitIndex - 1
        const nextIndex = currentExhibitIndex + 1

        const prevExhibit = listExhibitionsBefore[prevIndex] || {}
        const nextExhibit = listExhibitionsBefore[nextIndex] || {}

        const { ecmsEventPanelByPanelId: prevExhibitData = {} } = prevExhibit || {}
        prevExhibitCode = prevExhibitData.panelCode !== exhibitCode ? prevExhibitData.panelCode : ''
        const { ecmsEventPanelByPanelId: nextExhibitData = {} } = nextExhibit || {}
        nextExhibitCode = nextExhibitData.panelCode !== exhibitCode ? nextExhibitData.panelCode : ''
      }
    })

    return { prevExhibitCode, nextExhibitCode }
  }, [eventTours, eventId, exhibitCode, tourCode])

  // check exhibit has tourcode is same current tour code
  const isExhibitInTour = useMemo(() => {
    return eventToursExhibits.some((tourExhibit) => {
      const { ecmsEventTourByTourId: tourExhibitData } = tourExhibit || {}
      const { tourCode: exhibitTourCode } = tourExhibitData || {}

      return exhibitTourCode === tourCode
    })
  }, [eventToursExhibits, tourCode])

  /**
   * Go to another exhibit detail
   *
   * @param {String} exhibitCode Exhibit code
   */
  function goToOtherExhibitDetail(exhibitCode) {
    navigate(`/VIP/${tourCode}/Exhibit-${exhibitCode}`)
  }

  useEffect(() => {
    // Fetch analysis apis
    async function fetchApis() {
      await fetchApiAnalysis(getListPanelsViews)
    }

    fetchApis()
    pageView(trackingEventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trackingEventSource = TRACKING.SOURCE.TOUR.EXHIBIT_DETAIL + panelId

  return (
    <div className={'ExhibitDetail-area Layout-children'}>
      <SEO title={panelName} />
      <Container>
        <div className="ExhibitDetail-title">
          <span className="ExhibitDetail-index">
            {shortCode ? shortCode.substring(0, 3) : '01'}
          </span>
          <span className="ExhibitDetail-title_val">{panelName}</span>
        </div>
        <FeaturedImage
          image={featuredImgs[0] || featuredImgs[1]}
          isShowButtonNavigate={
            featuredImgs[0] || (!featuredImgs[0] && featuredImgs[1]) || !featuredImgs[1]
          }
          isExhibitInTour={isExhibitInTour}
          prevExhibitCode={prevExhibitCode}
          nextExhibitCode={nextExhibitCode}
          setImageViewer={(src) => setImgViewerSrc(src)}
          goToOtherExhibitDetail={goToOtherExhibitDetail}
        />
        <div className="ExhibitDetail-audio">
          <Wavesf
            size={screenWidth480 ? 30 : screenWidth768 ? 40 : 50}
            url={featuredAudio}
            withVolumeSlider={!screenWidth768}
            trackingEventSource={trackingEventSource}
          />
        </div>
        <FeaturedImage
          image={featuredImgs[0] && featuredImgs[1]}
          isExhibitInTour={isExhibitInTour}
          prevExhibitCode={prevExhibitCode}
          nextExhibitCode={nextExhibitCode}
          setImageViewer={(src) => setImgViewerSrc(src)}
          goToOtherExhibitDetail={goToOtherExhibitDetail}
        />
        <div className="ExhibitDetail-reference">
          <Button
            label={t('tour:lbl_resource_url_en')}
            className="ExhibitDetail-btn_website"
            href={resourceWebURL}
            disabled={!resourceWebURL}
            target="_blank"
          />
        </div>
        {featuredVideo && (
          <div className="ExhibitDetail-video">
            <VideoPlayer
              video={{ url: featuredVideo, fileCategory: 'webm' }}
              hiddenPip
              options={{ autoplay: false }}
              trackingEventSource={trackingEventSource}
            />
          </div>
        )}
      </Container>
      <div className="ExhibitDetail-other">
        <Container>
          <h4 className="ExhibitDetail-label">{t('lbl_description_en')}</h4>
          <p
            className="ExhibitDetail-desc"
            dangerouslySetInnerHTML={{ __html: panelDescription }}
          />
          {!IS_HIDDEN_VIEWS && (
            <div className="ExhibitDetail-analysis">
              <span className="ExhibitDetail-views">
                <span>{formatNumber(panelsViews[panelId])}</span> {t('lbl_views_en')}
              </span>
              {/* <div className="ExhibitDetail-listAnalysis">
              {icons.map((icon, index) => (
                <AnalysisItem key={index} icon={icon} />
              ))}
            </div> */}
            </div>
          )}
        </Container>
      </div>
      <ImageViewer isOpen={imgViewerSrc} src={imgViewerSrc} onClose={() => setImgViewerSrc(null)} />
    </div>
  )
}

export const query = graphql`
  query($exhibitCode: String!, $eventId: Int!) {
    postgres {
      ecmsEventPanelByPanelCode(panelCode: $exhibitCode) {
        panelId
        panelName
        panelCode
        panelDescription
        slidesHtml
        shortCode
        resourceWebsite
        ecmsEventTourPanelsByPanelId {
          nodes {
            ecmsEventTourByTourId {
              tourCode
            }
          }
        }
        ecmsEventFilesByPanelId {
          nodes {
            fileName
            keyCode
            url
          }
        }
      }
      allEcmsEventTours(condition: { eventId: $eventId }) {
        nodes {
          tourId
          tourCode
          eventId
          ecmsEventTourPanelsByTourId(orderBy: SEQUENCE_NO_ASC) {
            nodes {
              ecmsEventPanelByPanelId {
                panelId
                panelCode
              }
            }
          }
        }
      }
    }
  }
`
