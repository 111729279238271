import React from 'react'
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons'
import ImageSlider from 'components/widgets/ImageSlider'

export default function FeaturedImage(props) {
  const {
    image = '',
    isShowButtonNavigate,
    isExhibitInTour,
    prevExhibitCode,
    nextExhibitCode,
    setImageViewer = () => {},
    goToOtherExhibitDetail = () => {},
  } = props

  if (!image && !isShowButtonNavigate) return null

  return (
    <div className="ExhibitDetailSlide-wrapper">
      <div
        className={`ExhibitDetailSlide-container ${
          prevExhibitCode && prevExhibitCode && 'twoBtns'
        }`}
      >
        {image && (
          <ImageSlider
            className="ExhibitDetail-slider"
            onClickImage={(src) => setImageViewer(src)}
            images={[image]}
          />
        )}
        {isShowButtonNavigate && isExhibitInTour && prevExhibitCode && (
          <ArrowBackIcon
            className="ExhibitDetail-btn left"
            onClick={() => goToOtherExhibitDetail(prevExhibitCode)}
          />
        )}
        {isShowButtonNavigate && isExhibitInTour && nextExhibitCode && (
          <ArrowForwardIcon
            className="ExhibitDetail-btn right"
            onClick={() => goToOtherExhibitDetail(nextExhibitCode)}
          />
        )}
      </div>
    </div>
  )
}
