import React from 'react'
import Slider from 'react-slick'
import { size } from 'lodash'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.css'
import './index.scss'

export default function ImageSlider(props) {
  const { className = '', settings = {}, images = [], onClickImage = () => {} } = props
  const { slidesToShow = 1 } = settings || {}
  const imagesSize = size(images)

  const configs = {
    customPaging: (i) => {
      return (
        <span>
          <img src={images[i]} alt="" />
        </span>
      )
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    ...settings,
    slidesToShow: slidesToShow > imagesSize ? imagesSize : slidesToShow,
  }

  return (
    <div className={`ImageSlider-container ${className}`}>
      <Slider {...configs}>
        {images.map((image, index) => (
          <div key={index} className="ImageSlider-img" onClick={() => onClickImage(image)}>
            <div className="ImageSlider-img_wrapper" style={{ backgroundImage: `url(${image})` }} />
          </div>
        ))}
      </Slider>
    </div>
  )
}
